import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { AppContext } from "scripts/context/app-context";

import { Panel, Button, ButtonGroup } from "scripts/components/parts";
import { LayoutPanel } from "scripts/components/layouts";
import { Input, isInitialValid } from "scripts/components/form";
import { authenticationService } from "scripts/_services";
import { getError } from "scripts/_helpers";

class Login extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    // redirect to home if already logged in
    if (authenticationService.currentUserValue) {
      this.props.history.push("/");
    }

    this.state = {
      errorCount: 0,
    };
  }

  renderForm = (props) => {
    const { status, isValid } = props;

    const isFailed = status && !status.success;

    return (
      <Form className="form">
        <div className="form__group">
          <Input
            label={{ text: "Username" }}
            name={`username`}
            form={props}
            autofocus={isFailed ? false : true}
          />
        </div>
        <div className="form__group">
          <Input
            label={{ text: "Password" }}
            name={`password`}
            type={`password`}
            form={props}
            autofocus={isFailed ? true : false}
          />
        </div>

        {status && <div className="form__error">{status.message}</div>}

        <div className="form__submit">
          <ButtonGroup>
            <Button type="submit" disabled={!isValid}>
              Log In
            </Button>
            <Button to="/register" modifier="outline" fullWidth>
              Register new user
            </Button>
          </ButtonGroup>
        </div>
      </Form>
    );
  };

  handleSubmit = async (
    { username, password, startPage },
    { setStatus, setErrors, setSubmitting, setFieldValue, setTouched }
  ) => {
    const { showLoading, hideLoading } = this.context.loading;
    const { errorCount } = this.state;
    const { bar } = this.context;

    showLoading();
    setStatus();

    try {
      await authenticationService.login(username, password);
      // const { from } = this.props.location.state || { from: { pathname: "/" } };
      hideLoading();
      this.props.history.push({ pathname: "/" });
      // setStatus({success: true})
    } catch (err) {
      hideLoading();
      setSubmitting(false);

      setStatus({
        success: false,
        message: getError(err, "Username or password is incorrect"),
      });

      setFieldValue("password", "");
      setTouched(["password"], false);

      // if (disabled) {
      //   this.props.history.push("/account-disabled");
      //   return null;
      // }

      bar.showBar(
        {
          content: getError(err, "Username or password is incorrect"),
          modifier: "red",
        },
        8000
      );

      // If error is 5 or more, trigger help message
      this.setState({ errorCount: errorCount + 1 });
      if (errorCount >= 4) {
        this.triggerHelpMsg();
      }
    }
  };

  triggerHelpMsg = () => {
    const { openModal, closeModal } = this.context.modal;
    const title = "Need help?";
    const text = (
      <Fragment>
        <p>You've entered incorrect details 5 times.</p>
        <Button to="/forgotten-details" onClick={closeModal}>
          Reset Password
        </Button>
      </Fragment>
    );

    const bottom = (
      <p>
        <Link to="/register" onClick={closeModal}>
          I don't have an account yet
        </Link>
      </p>
    );

    const children = <Panel title={title} text={text} bottom={bottom} />;

    openModal(children);
  };

  render() {
    const title = "Log in";
      const text = <p>Login to your MyAccount<br /><br/>Please be aware Site Maintenance is scheduled from 6am Thursday 7 November until 6am Monday 11 November.<br/><br/>Any orders placed during this time will be held for processing until week commencing Monday 11 November.<br/><br/>For any orders or service requests you may require during the scheduled maintenance period, please consider submitting these before Thursday 7 November.</p>;
    const bottom = (
      <p>
        <Link to="/forgotten-details">Did you forget your log in details?</Link>
      </p>
    );

    return (
      <LayoutPanel className="login">
        <Panel title={title} text={text} bottom={bottom}>
          <Formik
            initialValues={{
              username: "",
              password: "",
            }}
            isInitialValid={isInitialValid}
            validationSchema={Yup.object().shape({
              username: Yup.string().required("Username is required"),
              password: Yup.string().required("Password is required"),
            })}
            onSubmit={this.handleSubmit}
            children={this.renderForm}
          />
        </Panel>
      </LayoutPanel>
    );
  }
}

export default Login;
